<template>
  <div :class="studentFlag ? 'css4' : 'css6'">
    <div class="tc-answer-content" v-loading="loading" element-loading-text="加载中"
      element-loading-spinner="el-icon-loading" element-loading-background="rgba(183,208,208,0.8)">
      <transition name="el-zoom-in-top">
        <div :class="studentFlag ? 'css5' : ''" v-if="brief">
          <template v-if="!newList.includes(measureInfo.measure_title)">
            <!-- 左边框 -->
            <div class="my_left_box"
              style="display: flex;padding:20px 20px 0 0;flex-direction: column;padding-left: 40px;">
              <div class="student-left-top" v-if="studentFlag">
                <div class="back-btn">
                  <button @click="backBtn" class="btn">返 回</button>
                </div>
                <div style="margin-left:30px">
                  <p style="color: #464C5C;">
                    {{ hours >= 10 ? hours : '0' + hours }}:{{ minute >= 10 ? minute : '0' + minute }}:{{ second >= 10 ? second
                      : '0' + second }}
                  </p>
                </div>
              </div>
              <div v-if="studentFlag" class="student-left-box">
                <div class="title">
                  答题卡
                </div>
                <div class="text-box">
                  <span>
                    共
                    <span v-if="measureInfo.questions" style="font-weight: bold;">{{
                      measureInfo.questions.length
                    }}</span>&nbsp;题&nbsp;
                  </span>
                  <!-- 已答题 -->
                  <span>
                    已答
                    <span style="color:#FF8A1D">{{ answer.length }}</span>&nbsp;题
                  </span>
                </div>
                <div class="div-box">
                  <div v-for="(item, key) in measureInfo.questions.length" :key="key" class="div-box-one">
                    <div class="one" v-if="key == index">{{ key + 1 }}</div>
                    <div class="two" v-if="key >= answer.length && key != index">{{ key + 1 }}</div>
                    <div v-if="key < answer.length && key != index" class="div">{{ key + 1 }}</div>
                  </div>
                </div>
                <div class="bottom-box">
                  <div>
                    <div
                      style="width:16px;height:16px;background:#DEE0E2;border-radius: 4px;display: inline-block;margin-left:30px">
                    </div><span style="margin-left:10px">已作答</span>
                  </div>
                  <div>
                    <div
                      style="width:16px;height:16px;background:#F7F8FB;border-radius: 4px;display: inline-block;margin-left:20px">
                    </div><span style="margin-left:10px">未作答</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="answer-content-layout">
              <div class="title">
                <p>{{ measureInfo.measure_title }}</p>
                <div :class="studentFlag ? 'progress-line-box' : ''">
                  <el-progress :percentage="Progress"></el-progress>
                </div>
              </div>
              <div class="answer-main" v-if="questionsList.length > 0">
                <!-- 题目标题 -->
                <!-- flase隐藏 -->
                <!-- {{flagNew3}} -->
                <!-- <div class="main-title" v-html="questionsList[index].question"></div> -->
                <div style="display:flex" v-show="!flagNew3 || this.titleGauge != 'WHO烟、酒和精神活性物质使用筛查量表(ASSIST)'">
                  <div>
                    <i class="el-icon-headset" style="color:skyblue;padding:10px;font-weight: bold;"
                      @click="hearClick(questionsList[index].question)"></i>
                    <!-- <el-button type="primary" icon="el-icon-headset" size="mini" ></el-button> -->
                  </div>
                  <div class="main-title" v-html="questionsList[index].question"></div>
                </div>

                <!-- 题目选项 -->
                <!-- {{this.index}} -->
                <div class="centen" v-show="!flagNew3 || this.titleGauge != 'WHO烟、酒和精神活性物质使用筛查量表(ASSIST)'">
                  <table @change="radio" v-if="!questionsList[index].isCheck">
                    <tr v-for="(item, key) in questionsList[index].options" :key="key">
                      <span style="display:flex;align-items: center;justify-content: center;">
                        <i class="el-icon-headset" style="color:skyblue;font-weight: bold;padding:14px 5px 0px 5px"
                          @click="hearClick(item)"></i>
                      </span>
                      <td v-if="measureInfo.measure_title == LES && inputQuesList.indexOf(index + 1) != -1">
                        <div> <el-input v-model="inputVal" type="number"></el-input></div>
                      </td>
                      <td v-else>
                        <input type="radio" :id="key" :value="key" v-model="picked" />
                        <label :for="key">
                          <i class="fa fa-square-o"></i>
                          <!-- <span>{{item}}</span> -->
                          <span v-html="item"></span>
                        </label>
                      </td>
                    </tr>
                  </table>
                  <table v-else>
                    <tr v-for="(item, key) in questionsList[index].options" :key="key">
                      <span style="display:flex;align-items: center;justify-content: center;cursor:pointer">
                        <i class="el-icon-headset" style="color:skyblue;font-weight: bold;padding:17px 5px 0px 5px"
                          @click="hearClick(item)"></i>
                      </span>
                      <td>
                        <el-checkbox :label="key" :value="key" @change="handleCheckBox(key)" v-model="checkList">{{ item
                        }}</el-checkbox>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="footer">
                <div class="btn">
                  <el-button @click="last">上一题</el-button>
                </div>
                <div class="sum" v-if="!studentFlag" v-show="this.titleGauge != 'WHO烟、酒和精神活性物质使用筛查量表(ASSIST)'">
                  <span>
                    共
                    <span class="sum_info" v-if="measureInfo.questions">{{
                      measureInfo.questions.length
                    }}</span>&nbsp;题&nbsp;
                  </span>
                  <br />
                  <span>
                    当前第
                    <span class="sum_info">{{ index + 1 }}</span>&nbsp;题
                  </span>
                </div>
                <div class="btn">
                  <el-button @click="next" v-if="(answer.length < questionsList.length ||
                      index < questionsList.length - 1) &&
                    !isEnd
                    ">下一题</el-button>
                  <!--  -->
                  <el-button @click="submit()" v-if="(answer.length >= questionsList.length &&
                      index >= questionsList.length - 1) ||
                    isEnd
                    " :disabled="isSubmit">提 交</el-button>
                </div>
              </div>
              <!-- ------------------测试模态框 ------------------------------------>
              <el-dialog :close-on-click-modal="false" title="测试专用" :visible.sync="testBox" width="50%" center>
                <el-form ref="testform" :model="testform" label-width="80px">
                  <el-form-item label="全选">
                    <el-radio-group v-model="testform.resource">
                      <el-radio label="A">全选 A</el-radio>
                      <el-radio label="B">全选 B</el-radio>
                      <el-radio label="C">全选 C</el-radio>
                      <el-radio label="D">全选 D</el-radio>
                      <el-radio label="E">全选 E</el-radio>
                      <el-radio label="F">全选 F</el-radio>
                      <el-radio label="G">全选 G</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="testBox = false">取 消</el-button>
                  <el-button type="primary" @click="setTest">提 交</el-button>
                </span>
              </el-dialog>
              <!-- ----------------------------------------------------------------->
            </div>
          </template>
          <div v-else>
            <!-- <el-button @click="shows">111</el-button> -->
            <!-- {{ measureInfo }} -->
            <div style="width: 100%;height: 87vh;" v-if="measureInfo.measure_title == '长时记忆测验'">
              <!-- <el-button type="small" style="position: absolute; right: 20em; top: 10em;" @click="longtests">提交答案</el-button> -->
              <iframe src="./static/test/longtest.html" frameborder="0" ref="longtest"
                style="width: 100%;height: 100%;"></iframe>
            </div>
            <div style="width: 100%;height: 87vh;" v-if="measureInfo.measure_title == '短时记忆测验'">
              <!-- <el-button type="small" style="position: absolute; right: 20em; top: 10em;" @click="shortTests">提交答案</el-button> -->
              <iframe src="./static/test/shortTest.html" frameborder="0" style="width: 100%;height: 100%;"
                ref="shortTest"></iframe>
            </div>
            <div style="width: 100%;height: 87vh;" v-if="measureInfo.measure_title == '瞬间记忆测试'">
              <!-- <el-button type="small" style="position: absolute; right: 20em; top: 10em;" @click="shuertes">提交答案</el-button> -->
              <iframe src="./static/test/shuerte.html" frameborder="0" style="width: 100%;height: 100%;"
                ref="shuerte"></iframe>
            </div>
            <div style="width: 100%;height: 87vh;" v-if="measureInfo.measure_title == '记忆保持测验'">
              <!-- <el-button type="small" style="position: absolute; right: 20em; top: 10em;" @click="memorys">提交答案</el-button> -->
              <iframe src="./static/test/memory.html" frameborder="0" style="width: 100%;height: 100%;"
                ref="memory"></iframe>
            </div>
            <div style="width: 100%;height: 87vh;" v-if="measureInfo.measure_title == '记忆再认测试'">
              <!-- <el-button type="small" style="position: absolute; right: 20em; top: 10em;" @click="memorydeeps">提交答案</el-button> -->
              <iframe src="./static/test/memorydeep.html" frameborder="0" style="width: 100%;height: 100%;"
                ref="memorydeep"></iframe>
            </div>
          </div>
        </div>

      </transition>
      <!-- 量表介绍 -->
      <div class="answer-content-layout" v-if="!brief">
        <div :class="studentFlag ? 'css44' : ''">
          <div class="title">
            <p>{{ measureInfo.measure_title }}</p>
          </div>
          <!-- <div class="describe">
          <p v-html="measureInfo.describe"></p>
        </div> -->
          <div class="msg" style="display:flex">
            <div>
              <i class="el-icon-headset" style="color:skyblue;padding:10px;font-weight: bold;margin-top:-20px"
                @click="hearClick(measureInfo.msg)"></i>
              <!-- <el-button type="primary" icon="el-icon-headset" size="mini" ></el-button> -->
            </div>
            <div :class="studentFlag ? 'scrollss' : ''">
              <p v-html="measureInfo.msg"></p>

            </div>
          </div>
          <el-row type="flex" justify="center" style="margin-top: 10px">

            <el-button type="primary" plain @click="hideBox" class="go-btn">{{ studentFlag ? "立即参与" : "开始答题" }}</el-button>
          </el-row>
        </div>

      </div>
      <!-- 偷偷加载 -->
      <div class="toto" v-show="false">
        <div v-for="(r, k) in questionsList" :key="k">
          <div v-html="r.question"></div>
          <div v-for="(r2, k2) in r.options" :key="k2">
            <div v-html="r2"></div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>
<script>
import axios from "axios";
// import { nextTick } from "q";
import { submit, savecache } from "@/api/answer.js";
import {
  loadAnswerList,
} from "@/api/answer.js";
export default {
  inject: ["reload"],
  data() {
    return {
      LES: '紧张性生活事件评定量表',
      inputVal: '',
      inputsList: [],
      inputQuesList: [5, 10, 15, 20, 25, 30, 43, 76, 113],
      hours: 0,
      minute: 0,
      second: 0,
      studentFlag: false,
      isSubmit: false, //提交按钮
      loading: true,
      testform: { resource: "" },
      testBox: false,
      ischeck: "",
      resource: "",
      picked: "",
      measureInfo: "",
      questionsList: [],
      oldObj: {},
      neWquestions: {},
      newAr: [],
      index: 0,
      answer: [],
      type: 0,
      measureId: "",
      uid: "",
      Progress: 0,
      brief: false,
      continueMes: true, // 连续填写问卷开关
      recordTiao: [],
      isEnd: false,
      input: "",
      mainTitleImg: "", //图片大小
      checkList: [], // 多选数组
      // 测试用的数据
      count: 0,
      timer: null,
      // 保存该量表的名字
      titleGauge: "",
      flag: 0,
      // flag1:0,
      // 第四题的选择答案
      four: 20,
      // 第五题选择答案
      five: 20,
      // 多一项控制提交显隐,用于自杀量表
      flag2: true,
      flag3: false,
      flagNew: 1,
      flagNew2: false,
      flagNew3: false,
      changeTen: "",
      changeThirty: "",
      thirty: null,
      oldquestionsList: [],
      bfquestionsList: [],
      newList: ['长时记忆测验', '短时记忆测验', '瞬间记忆测试', '记忆保持测验', '记忆再认测试'],
      inputsOfGame: ''
    };
  },
  created() {
    this.loadanswer();
    let student = localStorage.getItem("student");
    if (student == 1) {
      this.studentFlag = true;
    }
  },
  mounted() {
    window.addEventListener('message', e => {
      console.log(e, 'e');
      console.log(e.data?.abc);
      if (e.data.type == undefined) {
        switch (e.data) {
          case 'longtest':
            this.longtests()
            break;
          case 'shortTest':
            this.shortTests()
            break;
          case 'shuerte':
            this.shuertes()
            break;
          case 'memory':
            this.memorys()
            break;
          case 'memorydeep':
            this.memorydeeps()
            break;
          case 'message':
            this.showMessages()
            break;
        }
      }
    })
    let _this = this;
    document.onkeydown = (e) => {
      let el = e;
      this.str += el.keyCode + "_";

      if (_this.timer) clearTimeout(_this.timer);
      _this.timer = setTimeout(() => {
        console.log(e);
        if (this.str == "undefined49_" || this.str == "49_" || this.str == "undefined97_" || this.str == "97_") {
          // 点1选A
          this.picked = 0
          _this.clickInp(this.picked)
          this.str = "";
          // console.log("拼接",this.str);
        } else if (this.str == "undefined50_" || this.str == "50_" || this.str == "undefined98_" || this.str == "98_") {
          this.picked = 1
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined51_" || this.str == "51_" || this.str == "undefined99_" || this.str == "99_") {
          this.picked = 2
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined52_" || this.str == "52_" || this.str == "undefined100_" || this.str == "100_") {
          this.picked = 3
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined53_" || this.str == "53_" || this.str == "undefined101_" || this.str == "101_") {
          this.picked = 4
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined54_" || this.str == "54_" || this.str == "undefined102_" || this.str == "102_") {
          this.picked = 5
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined55_" || this.str == "55_" || this.str == "undefined103_" || this.str == "103_") {
          this.picked = 6
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined56_" || this.str == "56_" || this.str == "undefined104_" || this.str == "104_") {
          this.picked = 7
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined57_" || this.str == "57_" || this.str == "undefined105_" || this.str == "105_") {
          this.picked = 8
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined49_48_" || this.str == "49_48_" || this.str == "undefined97_96_" || this.str == "97_96_") {
          this.picked = 9
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined49_49_" || this.str == "49_49_" || this.str == "undefined97_97_" || this.str == "97_97_") {
          this.picked = 10
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined49_50_" || this.str == "49_50_" || this.str == "undefined97_98_" || this.str == "97_98_") {
          this.picked = 11
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined49_51_" || this.str == "49_51_" || this.str == "undefined97_99_" || this.str == "97_99_") {
          this.picked = 12
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined49_52_" || this.str == "49_52_" || this.str == "undefined97_100_" || this.str == "97_100_") {
          this.picked = 13
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined49_53_" || this.str == "49_53_" || this.str == "undefined97_101_" || this.str == "97_101_") {
          this.picked = 14
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined49_54_" || this.str == "49_54_" || this.str == "undefined97_102_" || this.str == "97_102_") {
          this.picked = 15
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined49_55_" || this.str == "49_55_" || this.str == "undefined97_103_" || this.str == "97_103_") {
          this.picked = 16
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined49_56_" || this.str == "49_56_" || this.str == "undefined97_104_" || this.str == "97_104_") {
          this.picked = 17
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined49_57_" || this.str == "49_57_" || this.str == "undefined97_105_" || this.str == "97_105_") {
          this.picked = 18
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined50_48_" || this.str == "50_48_" || this.str == "undefined98_96_" || this.str == "98_96_") {
          this.picked = 19
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined50_49_" || this.str == "50_49_" || this.str == "undefined98_97_" || this.str == "98_97_") {
          this.picked = 20
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined50_50_" || this.str == "50_50_" || this.str == "undefined98_98_" || this.str == "98_98_") {
          this.picked = 21
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined50_51_" || this.str == "50_51_" || this.str == "undefined98_99_" || this.str == "98_99_") {
          this.picked = 22
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined50_52_" || this.str == "50_52_" || this.str == "undefined98_100_" || this.str == "98_100_") {
          this.picked = 23
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined50_53_" || this.str == "50_53_" || this.str == "undefined98_101_" || this.str == "98_101_") {
          this.picked = 24
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined50_54_" || this.str == "50_54_" || this.str == "undefined98_102_" || this.str == "98_102_") {
          this.picked = 25
          _this.clickInp(this.picked)
          this.str = "";
        } else if (this.str == "undefined50_55_" || this.str == "50_55_" || this.str == "undefined98_103_" || this.str == "98_103_") {
          this.picked = 26
          _this.clickInp(this.picked)
          this.str = "";
        }

        this.str = "";
      }, 500);
    }
    this.timeGo()
    // this.timer = tools.timedown(25)
  },
  methods: {
    showMessages(type = 'warning', duration = 1500) {
      this.$message({
        showClose: true,
        duration: duration,
        message: "请先选择答案哦",
        type: type,
      });
    },
    // shows() {
    //   console.log(this.inputsOfGame);
    // },
    shortTests() {
      this.inputsOfGame = this.$refs.shortTest.contentWindow.toVues()
      this.submitGame()
    },
    longtests() {
      this.inputsOfGame = this.$refs.longtest.contentWindow.toVues()
      this.submitGame()
    },
    shuertes() {
      this.inputsOfGame = this.$refs.shuerte.contentWindow.toVues()
      this.submitGame()
    },
    memorys() {
      this.inputsOfGame = this.$refs.memory.contentWindow.toVues()
      this.submitGame()
    },
    memorydeeps() {
      this.inputsOfGame = this.$refs.memorydeep.contentWindow.toVues()
      this.submitGame()
    },
    backBtn() {
      this.brief = false;
    },
    timeGo() {
      setInterval(() => {
        this.second++;
      }, 1000)
    },
    // 页面缓存
    hearClick(text) {
      const synth = window.speechSynthesis;
      const msg = new SpeechSynthesisUtterance();
      msg.text = text; // 文字内容: 小朋友，你是否有很多问号
      msg.lang = "zh-CN"; // 使用的语言:中文
      //  msg.volume = 1; // 声音音量：1
      //  msg.rate = 1; // 语速：1
      //  msg.pitch = 1; // 音高：1
      synth.speak(msg);
    },
    clickInp(picked) {
      var p = document.querySelectorAll("input[type='radio']");
      // console.log(p);
      // console.log("数字",picked);
      if (Number(picked + 1) > p.length) {
        console.log("p", p.length);
        this.$message({
          showClose: true,
          duration: 1500,
          message: "选择提供答案后，才能进入下一题",
          type: "warning",
        });
      }
      p.forEach((v, i) => {
        if (i == picked) {
          v.click()
        }
      });
    },
    async savecache(data) {
      await savecache(data);
    },
    async getcache(data) {
      data.measureId = this.$route.query.id;
      let res = await savecache(data);
      return res;
    },
    // ----------------------测试专用------------------------------
    showtestBox() {
      this.testBox = true;
    },
    hideBox() {
      this.brief = true;
      // ['长时记忆测验','短时记忆测验','瞬间记忆测试','记忆保持测验','记忆再认测试']
      // this.$nextTick(() => {
      //   console.log(this.measureInfo.measure_title)
      //   if(this.measureInfo.measure_title == '长时记忆测验') {
      //     console.log(this.$refs.longtest.contentWindow)
      //   }
      //   if(this.measureInfo.measure_title == '短时记忆测验') {
      //     this.$refs.shortTest.contentWindow.start()
      //   }
      //   // if(this.measureInfo.measure_title == '瞬间记忆测试') {
      //   //   this.$refs.shuerte.contentWindow.start()
      //   // }
      //   if(this.measureInfo.measure_title == '记忆保持测验') {
      //     this.$refs.memory.contentWindow.start()
      //   }
      //   if(this.measureInfo.measure_title == '记忆再认测试') {
      //     this.$refs.memorydeep.contentWindow.start()
      //   }
      // })
      // console.log(tools.arr);

      // this.startCountdown(5)
      // this.text = '请在倒计时结束前尽可能多的记住数字方格所在的位置。'
      // this.initCourt(0)
      // this.$nextTick(() => {
      // 	this.timer = setInterval(() => {
      // 		if(this.count > 26) {
      // 			clearInterval(this.timer)
      // 		}
      // 		this.count += 1;
      // 		this.handleClick();
      // 	},500)
      // })
      this.handleBlur();
    },




    // 游戏提交逻辑
    // submitOfGame(val) {
    //   console.log(val);
    //   this.isSubject = false
    //   this.arrIndex++
    // },


    setTest() {
      // 数据处理
      this.questionsList.forEach((v, k) => {
        let obj = { id: v.id, answer: this.testform.resource };
        this.answer.push(obj);
      });
      this.submit();
    },
    // ----------------------------------------------------
    // 单选监控
    radio(val) {
      if (this.measureInfo.measure_title == this.LES && this.inputQuesList.indexOf(this.index + 1) != -1) {
        return;
      };
      // // 字体图标的变换
      var p = document.querySelectorAll(".fa");
      // // 1-还原所有
      p.forEach((v) => {
        v.classList.remove("fa-check-square");
        v.classList.add("fa-square-o");
      });
      // // 2-判断类名，设置类名
      var className = val.target.parentNode.children[1].children[0].getAttribute(
        "class"
      );
      if (className == "fa fa-square-o") {
        val.target.parentNode.children[1].children[0].classList.remove(
          "fa-square-o"
        );
        val.target.parentNode.children[1].children[0].classList.add(
          "fa-check-square"
        );
      }
      // // tr 背景的切换
      // // 1-清除所有 tr背景
      var tr = document.querySelectorAll("tr");
      tr.forEach((v) => {
        v.classList.remove("active");
      });
      // // 2-设置当前tr背景
      val.target.parentNode.parentNode.classList.add("active");
      setTimeout(() => {
        this.next();
      }, 250);
    },
    async loadanswer() {
      let id = this.$route.query.id;
      this.measureId = this.$route.query.id;
      let r = await axios.get(`/get_measure_by_id?id=${id}`);
      // console.log("400400",r.data);
      if (r.data.data) {
        this.titleGauge = r.data.data.measure_title || ""
      }
      if (r.data.code == 400400) {
        this.$message({
          message: r.data.msg,
          type: "warning",
          center: true,
          showClose: true,
          duration: 1500,
        });
        this.$router.go(-1); //返回上一层
        return;
      }
      this.measureInfo = r.data.data;
      //  题目数据
      // r.data.data.questions.forEach((v,k)=>{
      //   var thishtml = [];
      //   v.options.forEach((vs,ks)=>{
      //     //console.log(vs);
      //       vs = vs.replace("<imgdiv>", "<img src='/ruiwen/ruiwen/");
      //       vs = vs.replace("</imgdiv>", ".jpg'>");
      //       thishtml[ks] = vs;
      //   })
      //   v = thishtml;
      //   console.log(thishtml);
      // })
      // console.log(r.data.data.questions)
      this.questionsList = [...r.data.data.questions];
      this.questionsList.filter(item => {
        this.bfquestionsList.push(item)
      })
      //
      if (r.data.data.measure_title == "WHO烟、酒和精神活性物质使用筛查量表(ASSIST)") {
        this.questionsList.forEach((item, index) => {
          item.num = index + 1;
          return item;
        })
        this.oldObj = this.questionsList[70];
        // 进行分配以0-9为根
        this.neWquestions = {
          "1": [this.questionsList[10], this.questionsList[20], this.questionsList[30], this.questionsList[40], this.questionsList[50], this.questionsList[60]],
          '2': [this.questionsList[11], this.questionsList[21], this.questionsList[31], this.questionsList[41], this.questionsList[51], this.questionsList[61]],
          '3': [this.questionsList[12], this.questionsList[22], this.questionsList[32], this.questionsList[42], this.questionsList[52], this.questionsList[62]],
          '4': [this.questionsList[13], this.questionsList[23], this.questionsList[33], this.questionsList[43], this.questionsList[53], this.questionsList[63]],
          '5': [this.questionsList[14], this.questionsList[24], this.questionsList[34], this.questionsList[44], this.questionsList[54], this.questionsList[64]],
          '6': [this.questionsList[15], this.questionsList[25], this.questionsList[35], this.questionsList[45], this.questionsList[55], this.questionsList[65]],
          '7': [this.questionsList[16], this.questionsList[26], this.questionsList[36], this.questionsList[46], this.questionsList[56], this.questionsList[66]],
          '8': [this.questionsList[17], this.questionsList[27], this.questionsList[37], this.questionsList[47], this.questionsList[57], this.questionsList[67]],
          '9': [this.questionsList[18], this.questionsList[28], this.questionsList[38], this.questionsList[48], this.questionsList[58], this.questionsList[68]],
          '10': [this.questionsList[19], this.questionsList[29], this.questionsList[39], this.questionsList[49], this.questionsList[59], this.questionsList[69]],
        }
        // this.neWquestions.push(obj)
      }
      // console.log("questionsList",this.questionsList);
      // console.log("neWquestions",this.neWquestions);
      // 如果是
      // console.log(r.data.data.questions)
      const reg = /\$\(.+,.+\)/g; // 匹配$()里面的内容
      const reg1 = /\(([^()]+)\)/; // 匹配()里面的内容
      let content = "";
      let arr = [];
      // 处理量表标题里面的特殊字符串
      this.questionsList.forEach((value) => {
        if (reg.test(value.question)) {
          content = value.question.match(reg)[0];
          arr = content.match(reg1)[1].split(",");
          value[arr[0]] = arr[1];
          value["title"] = "";
          value.question = value.question.replace(
            content,
            `<input @blur="handleBlur" ref="title" type="text" style="width:100px;border:none;border-bottom:1px solid" />`
          );
          // value['isCheck'] = true;
        }
        if (value.extra && value.extra.checkbox) {
          value["isCheck"] = true;
        }
        // if(value.)
      });
      // console.log(this.questionsList);
      this.loading = false;
      // //console.log(this.measureInfo);
      this.type = r.data.show_result;
      // 获取缓存,续题
      this.uid = localStorage.getItem("user_id");

      let res = await this.getcache({
        key: `measureId${this.uid}-${this.measureId}`,
      });
      if (res.data) this.answer = JSON.parse(res.data);

      if (this.answer.length > 0) {
        this.brief = true;
        this.$confirm("是否从上一次答题处开始?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            // 加载LES缓存
            if (localStorage.getItem("inputsList")) {
              this.inputsList = JSON.parse(localStorage.getItem("inputsList"));
            }
            var arr = [
              "A",
              "B",
              "C",
              "D",
              "E",
              "F",
              "G",
              "H",
              "I",
              "J",
              "K",
              "L",
              "M",
              "N",
              "O",
              "P",
              "Q",
              "R",
              "S",
              "T",
              "U",
              "V",
              "W",
              "X",
              "Y",
              "Z",
            ];

            // this.answer = JSON.parse(
            //   localStorage.getItem(`measureId${this.uid}-${this.measureId}`)
            // );
            // this.recordTiao =
            //   JSON.parse(
            //     localStorage.getItem(`recordTiao${this.uid}-${this.measureId}`)
            //   ) ?? [];
            let res2 = await this.getcache({
              key: `recordTiao${this.uid}-${this.measureId}`,
            });
            if (res2.data) this.recordTiao = JSON.parse(res2.data);

            if (this.answer.length >= this.questionsList.length) {
              this.index = this.answer.length - 1;
            } else {
              this.index = this.answer.length;
            }
            // this.index -= 1;
            let i = arr.indexOf(this.answer[this.index - 1].answer);
            if (
              i !== -1 &&
              this.questionsList[this.index - 1].jump_info[i] >
              this.questionsList.length
            ) {
              this.index -= 1;
              this.picked = i;
              this.$nextTick(() => {
                document.querySelectorAll("tr")[i].classList.add("active");
                document
                  .querySelectorAll(".fa")
                [i].classList.add("fa-check-square");
              });
              this.isEnd = true;
            }
            // 计算进度条
            this.Progress = Math.ceil(
              (this.answer.length / this.questionsList.length) * 100
            );
            // 如果是最后一题,则渲染并 提示提交
            if (this.answer.length == this.questionsList.length) {
              //模拟已选择
              var answers = this.answer[this.answer.length - 1].answer;
              var index = arr.indexOf(answers);
              this.picked = index;
              this.$nextTick(() => {
                document.querySelectorAll("tr")[index].classList.add("active");
                document
                  .querySelectorAll(".fa")
                [index].classList.add("fa-check-square");
              });
            }
          })
          .catch(() => {
            // localStorage.removeItem(`measureId${this.uid}-${this.measureId}`);
            // localStorage.removeItem(`recordTiao${this.uid}-${this.measureId}`);

            // 删除LES缓存
            if (localStorage.getItem("inputsList")) {
              localStorage.removeItem("inputsList");
            }
            this.savecache({
              key: `measureId${this.uid}-${this.measureId}`,
              value: "",
              t: 0,
              measureId: this.$route.query.id,
            });
            this.savecache({
              key: `recordTiao${this.uid}-${this.measureId}`,
              value: "",
              t: 0,
              measureId: this.$route.query.id,
            });
            this.$message({
              type: "info",
              showClose: true,
              duration: 1500,
              message: "已重置",
            });
            this.brief = false;
            if (this.titleGauge == "WHO烟、酒和精神活性物质使用筛查量表(ASSIST)") {
              this.answer = [];
            }
          });
      }
    },

    // 下一题
    next() {
      // console.log("选择",this.picked);
      // 当前任务ID
      // 当前任务ID
      // return;
      var id = this.questionsList[this.index].id;
      let arrNew = [...this.questionsList]
      // console.log("7777",arrNew);
      // console.log("ID",arrNew);
      // return
      if (arrNew.length >= 4) {
        if (id == arrNew[3].id) {
          this.four = this.picked
          // console.log("这4道题答案",this.five);
        }
      }
      if (arrNew.length >= 5) {
        if (id == arrNew[4].id) {
          this.five = this.picked
          // console.log("这5道题答案",this.five);
        }
      }
      // 首先是量表名称,然后取当前的id,以及选项答案,判断第四题第五题答案
      // console.log("哪道题",id);
      if (this.titleGauge == "自杀意念量表(SSI)") {
        if (this.four != 0 || this.five != 0) {
          this.flag = 0
        }
        if (this.four == 0 && this.five == 0 && id == arrNew[4].id) {
          this.flag = 1
          // console.log(this.flag);
          // 切换成提交按钮
          this.isEnd = true
          this.flag2 = false
          return
        } else if (id == arrNew[arrNew.length - 1].id) {
          // 最后一道题的时候,变为真
          this.isEnd = true
          this.flag2 = false
          // this.flag3=true
        } else {
          this.isEnd = false
          this.flag2 = true
        }
      }
      // 生活事件量表(LES)是否输入inputVal判断
      if (this.measureInfo.measure_title === this.LES && this.inputQuesList.indexOf(this.index + 1) !== -1 && this.inputVal === '') {
        this.$message({
          showClose: true,
          duration: 1500,
          message: "请输入答案后进入下一题",
          type: "warning",
        });
        return;
      } else if (this.measureInfo.measure_title === this.LES && this.inputQuesList.indexOf(this.index + 1) !== -1 && this.inputVal * 1 < 1) {
        this.$message({
          showClose: true,
          duration: 1500,
          message: "输入值不能小于1",
          type: "warning",
        });
        return;
      }

      if (this.inputVal !== "" && !this.inputsList[`${this.index + 2}`]) {
        this.inputsList[`${this.index + 1}`] = this.inputVal;
        localStorage.setItem("inputsList", JSON.stringify(this.inputsList));
        this.inputVal = "";
      };

      // 判断LEC
      if (this.measureInfo.measure_title === this.LES && this.inputsList[`${this.index + 2}`]) {
        this.inputVal = this.inputsList[`${this.index + 2}`];
      }
      // console.log("下一题",this.picked);
      // 判断能否进入下一题
      if (this.measureInfo.measure_title === this.LES && this.inputQuesList.indexOf(this.index + 1) !== -1) {
        console.log(1);
      } else if (this.picked === "" && !this.checkList.length) {
        this.$message({
          showClose: true,
          duration: 1500,
          message: "选择后，才能进入下一题",
          type: "warning",
        });
        return;
      }

      if (
        this.checkList.length <
        this.questionsList[this.index].extra?.checkbox?.min
      ) {
        this.$message({
          showClose: true,
          duration: 1500,
          message: `最少选择${this.questionsList[this.index].extra.checkbox.min
            }`,
          type: "warning",
        });
        return;
      }

      // 提交新数据处理
      // arr：选项A-Z
      var arr = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      let oldEl = this.questionsList[this.index]; // 拿到当前的题目
      var answer = arr[this.picked]; //this.picked 当前选择选项序列号
      if (oldEl.isCheck) {
        answer = [...this.checkList];
      }

      var obj = { id, answer }; //  当前题目答案包装
      if (this.titleGauge == "WHO烟、酒和精神活性物质使用筛查量表(ASSIST)") {
        var obj = { id, answer, num: this.questionsList[this.index].num }; //  当前题目答案包装
      }

      // 判断是否已经添加过数据
      var tmp = this.answer.some((v) => {
        if (v.id == id) {
          return true;
        } else {
          return false;
        }
      });

      // 判断是更新还是新增;
      if (tmp) {
        // 更新
        this.answer[this.index] = obj;
      } else {
        // 新增
        this.answer.push(obj);

        this.savecache({
          key: `measureId${this.uid}-${this.measureId}`,
          value: JSON.stringify(this.answer),
          t: 0,
          measureId: this.$route.query.id,
        });
        // localStorage.setItem(
        //   `measureId${this.uid}-${this.measureId}`,
        //   JSON.stringify(this.answer)
        // );
        // 计算进度条
        this.Progress = Math.ceil(
          (this.answer.length / this.questionsList.length) * 100
        );

      }


      // 判断是否完成
      if (this.index >= this.questionsList.length - 1) {
        // 外网提示
        this.$message({
          showClose: true,
          duration: 1500,
          message: "已经完成了所有题目,没有问题就可以点击提交了哦",
          type: "warning",
        });
        // 内网自动提交
        // this.submit();
        return;
      }
      this.checkList = [];
      // // 字体图标的变换
      var p = document.querySelectorAll(".fa");
      // // 1-还原所有
      p.forEach((v) => {
        v?.classList.remove("fa-check-square");
        v?.classList.add("fa-square-o");
      });
      var tr = document.querySelectorAll("tr");
      tr.forEach((v) => {
        v?.classList.remove("active");
      });

      if (this.titleGauge == "WHO烟、酒和精神活性物质使用筛查量表(ASSIST)") {
        // 记录前10题的选项
        // console.log("WHO烟、酒和精神活性物质使用筛查量表(ASSIST)下一题",);
        // console.log("烟、酒",this.answer);
        // console.log("当前第几题",(this.index + 1));
        // 1.前面在这个方法结束后,进行记录选项
        // 2.在第十题结束之后;进行题目数据重新处理,选出对应的题目整体数据更换（然后进行展示操作）
        // 3.注意如果前十题返回更改后,一旦更改进行判定删除相应数据；（来回点的时候重新复现）
        // return;
        // console.log(this.answer);
        if ((this.index + 1) == 10) {
          let arr = this.answer.map(item => {
            return item.answer
          });

          // console.log("烟酒前十",arr.join().substring(0,20));
          if (arr.join().substring(0, 20) == 'B,B,B,B,B,B,B,B,B,B' || arr.join().substring(0, 20) == 'B,B,B,B,B,B,B,B,B,B,') {
            this.questionsList = this.questionsList.filter((item, index) => {
              if (index < 10) {
                return item
              }
            })
            // console.log("数据",this.questionsList);
            // console.log("进入",this.flagNew3);
            this.flagNew3 = true;
            setTimeout(() => {
              this.picked = 1
              this.clickInp(this.picked)
            }, 300);
          } else {
            this.flagNew3 = false;
          }

          if (arr.join().substring(0, 20) == "A,A,A,A,A,A,A,A,A,A") {
            this.flagNew = 0
          } else {
            this.flagNew = 1
          }

          // 全选b就不用管后面的
          this.changeTen = arr.join();
        }
        // 等于第二十几题的最后一题的时候
        console.log("this.thirty", this.thirty);
        console.log("this.index", this.answer[this.index].num);
        if (this.answer[this.index].num == this.thirty) {
          // console.log("第几题",this.answer[this.answer.length-1].num);
          // console.log("this.answer",this.answer);
          // 调用
          let arr = []
          this.answer.forEach(item => {
            if (item.num > 10 && item.num < 21) {
              // console.log(item.answer);
              // return item.answer
              arr.push(item.answer)
            }
          });
          // console.log("烟酒前20",arr);
          this.changeThirty = arr.join();
          this.changeSubject(30, this.changeThirty);
        }

      }

      // --------------------------跳题-start------------------------------------
      // 1.判断是否跳题
      // 2.判断需要跳多少题
      // 3.把跳过的题添加为空的答案
      // 4.记录调后的题目.打上标记方便回跳
      // ++++++++++
      // this.questionsList[this.index])：跳题之前的题目
      // this.picked：选项下标
      let tiao = this.questionsList[this.index].jump_info[this.picked];
      //olIndex：开始添加空题的下标     isIndex：当前题目下标
      let olIndex = JSON.parse(JSON.stringify(this.index)) + 1;
      let isIndex = JSON.parse(JSON.stringify(this.index));
      if (tiao != undefined && tiao > this.index) {
        if (tiao > this.questionsList.length) {
          tiao = this.index + 1;
          this.isEnd = true;
          this.$message.success("当前可以提交");
          this.answer.splice(tiao);
        } else {
          this.isEnd = false;
        }

        // 跳题后的下标
        this.index = tiao - 1;
        // 处理已经跳过题的答案 olIndex +1  this.index
        for (olIndex; olIndex < this.index; olIndex++) {
          let obj = {
            id: this.questionsList[olIndex].id,
            answer: "",
          };
          if (tmp) {
            this.answer[olIndex] = obj;
          } else {
            this.answer.push(obj);
          }
        }
        // localStorage.setItem(
        //   `measureId${this.uid}-${this.measureId}`,
        //   JSON.stringify(this.answer)
        // );
        this.savecache({
          key: `measureId${this.uid}-${this.measureId}`,
          value: JSON.stringify(this.answer),
          t: 0,
          measureId: this.$route.query.id,
        });

        // 计算进度条
        this.Progress = Math.ceil(
          (this.answer.length / this.questionsList.length) * 100
        );
        // 记录跳题后的ID 用于 用于回溯
        let recordTmp = {
          id: this.questionsList[this.index].id,
          from: isIndex,
        };
        this.recordTiao.push(recordTmp);
        // localStorage.setItem(
        //   `recordTiao${this.uid}-${this.measureId}`,
        //   JSON.stringify(this.recordTiao)
        // );
        this.savecache({
          key: `recordTiao${this.uid}-${this.measureId}`,
          value: JSON.stringify(this.recordTiao),
          t: 0,
          measureId: this.$route.query.id,
        });
      } else {
        // 页码+1
        this.index++;
      }

      // --------------------------跳题-end--------------------------------------
      this.handleBlur();
      // 清空
      this.picked = "";
      // 渲染已选择的题目
      this.$nextTick(() => {
        if (
          this.questionsList[this.index].isCheck &&
          this.answer[this.index]?.answer
        ) {
          this.checkList = this.answer[this.index].answer;
        }
        this.loadnext();
        let el = this.questionsList[this.index]; // 拿到当前的这个题目
        this.questionsList.forEach((value) => {
          if (
            value["text"] === el["text"] &&
            value["id"] !== el["id"] &&
            value.title
          ) {
            const input = document.querySelector(".main-title input");
            if (!el.title) {
              el.title = value.title;
              input.value = value.title;
            } else {
              input.value = el.title;
            }
          }
        });
      });


    },
    // 上一题
    last() {
      if (this.index <= 0) {
        this.$message({
          showClose: true,
          duration: 1500,
          message: "已经是第一道题了",
          type: "warning",
        });
        return;
      }
      // console.log(this.answer[this.index].num);
      if (this.index < 11) {
        this.flagNew3 = false;
      }
      this.isEnd = false;
      // 查找跳题记录中是否存在
      let a = this.recordTiao.find((v) => {
        return v.id == this.questionsList[this.index].id;
      });
      if (a != undefined) {
        this.index = a.from;
      } else {
        this.index--;
      }
      this.$nextTick(() => {
        if (this.measureInfo.measure_title === this.LES && this.inputQuesList.indexOf(this.index + 1) !== -1) {
          this.inputVal = this.inputsList[`${this.index + 1}`]
        } else {
          this.loadnext();
          const input1 = document.querySelector(".main-title input");
          if (this.questionsList[this.index].title) {
            input1.value = this.questionsList[this.index].title;
          }
          if (this.questionsList[this.index].isCheck) {
            this.checkList = this.answer[this.index].answer;
          }
        }
      });
    },
    // 渲染已选择的题目
    loadnext() {
      var id = this.questionsList[this.index].id;
      // 先判断是否有值，没有则 退出
      var tmp = this.answer.some((v) => {
        if (v.id == id) {
          return true;
        } else {
          return false;
        }
      });
      if (!tmp) {
        return;
      }
      // 清除样式
      var p = document.querySelectorAll(".fa");
      p.forEach((v) => {
        v.classList.remove("fa-check-square");
        v.classList.add("fa-square-o");
      });
      var tr = document.querySelectorAll("tr");
      tr.forEach((v) => {
        v.classList.remove("active");
      });
      //模拟已选择
      var arr = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      var answers = this.answer[this.index].answer;
      var index = arr.indexOf(answers);
      this.picked = index;
      document.querySelectorAll("tr")[index]?.classList.add("active");
      document.querySelectorAll(".fa")[index]?.classList.add("fa-check-square");
    },
    // 自杀量表单独处理,当四五题选A点击保存的时候,
    midwayJump() {
      // console.log(9999);
      this.isSubmit = true;
      var arr = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      let i;
      let a = [];
      let newA = [...this.questionsList];
      let o = {};
      // 为this.answer自动补全
      this.answer.forEach((value, index) => {
        if (value.answer instanceof Array) {
          value.answer = value.answer
            .map((value) => (value = arr[value * 1]))
            .join(",");
        }
        if (value.answer) {
          i = arr.indexOf(value.answer);
          if (
            this.questionsList[index].jump_info[i] > this.questionsList.length
          ) {
            a = newA.splice(index + 1);
            a = a.map((val) => {
              o = { id: val.id, answer: "" };
              return o;
            });
            this.answer.push(...a);
          }
        }
      });
      let obj = {
        measureId: this.measureId,
        systemAccess: {
          accessToken: "md5",
        },
        isSave: true,
        answer: this.answer,
        inputs: {},
      };
      newA.forEach((value) => {
        if (value.title || value.text) {
          obj.inputs[value.text] = value.title;
        }
      });
      if (this.measureInfo.measure_title === this.LES) {
        obj.inputs = this.inputsOfGame;
        obj.answer.forEach((item) => {
          if (!item.answer) {
            item.answer = "";
          }
        });
      }
      obj.user_id = localStorage.getItem("user_id");
      // 拷贝对象,处理数据
      // console.log("发送数据",obj);
      let obj2 = Object.assign({}, obj);
      let arr2 = [...this.questionsList];
      let arr3 = []
      arr2.filter((item, index) => {
        if (index > 3) {
          let obj3 = {}
          obj3.id = item.id
          obj3.answer = "A"
          arr3.push(obj3)
        }
      })
      // 把第一项改为A
      // arr3[0].answer=this.five
      obj2.answer = obj2.answer.slice(0, 4).concat(arr3)
      // console.log(obj2.answer);
      // console.log(obj2);
      console.log('2222222222222')
      submit(obj2)
        .then((res) => {
          let id = res.data.id;
          if (res.code == 400200) {
            // console.log(123);
            this.$message({
              message: "恭喜你，提交成功",
              type: "success",
              showClose: true,
              duration: 1500,
            });

            localStorage.removeItem("inputsList");
            // 判断是否继续填写问卷
            if (this.continueMes) {
              // 调用拉取数据列表，如果有没有做的数据，得到没有做的数据（allMesId判断）
              this.loadAnserData()
            } else {
              if (this.type === 1) {
                this.$router.push(`/answer_content/answerChart?id=${id}`);
              } else {
                this.$router.push(`/answer_content/answer_list`);
              }
            }
            this.isSubmit = false;
            localStorage.removeItem(`measureId${this.uid}-${this.measureId}`);
            localStorage.removeItem(`recordTiao${this.uid}-${this.measureId}`);
          } else {
            this.isSubmit = false;
          }
        })
        .catch((err) => {
          this.isSubmit = false;
        });
      // console.log("拷贝数据",obj2);
      // console.log("题目数据",this.questionsList);
      // obj2.answer
      return
    },
    // 加载列表
    async loadAnserData() {
      await loadAnswerList().then((res) => {
        if (res.code === 400200) {
          let arrID = "";
          res.data.forEach((item) => {
            if (item.buff == 0) {
              arrID += item.id + ",";
            }
          });
          arrID = arrID.substr(0, arrID.length - 1).split(",");
          console.log("arrID", arrID);
          localStorage.setItem("allMesId", JSON.stringify(arrID));

          let allMesId = JSON.parse(localStorage.getItem("allMesId"));

          // allMesId = allMesId.filter((v) => {
          //   return v != this.$route.query.id;
          // });
          // console.log("allMesId",allMesId);
          // return;
          // z;

          if (allMesId.length == 0 || allMesId[0] == "") {
            // this.$router.push(`/answer_content/answerUserMeasure`);
            this.$router.push(`/answer_content/answer_list`);

          } else {
            let index = allMesId.indexOf(this.measureId)
            if (index < allMesId.length - 1) {
              this.$router.push(`/answer_content?id=${allMesId[index + 1]}`);
              localStorage.setItem("allMesId", JSON.stringify(allMesId));
              this.reload();
            } else {
              this.$message({
                message: "最后一题了哦",
                type: "warning",
                showClose: true,
                duration: 1500,
              });
              this.$router.push(`/answer_content/answer_list`);
              this.isSubmit = false;
            }
          }
        }
      });
    },
    TobaccoAndWine() {
      // console.log("WHO烟、酒和精神活性物质使用筛查量表(ASSIST)",this.answer);
      var arr = [];
      for (var i = 0; i < this.bfquestionsList.length; i++) {
        // console.log(this.bfquestionsList[i]);
        let obj = {
          id: this.bfquestionsList[i].id,
          num: this.bfquestionsList[i].num,
          answer: "A",
        }
        arr.push(obj)
      }
      arr.filter((item, index) => {
        this.answer.filter(item2 => {
          if (item2.id == item.id) {
            item.answer = item2.answer
          }
        })
      })
      // console.log("71个写活",arr);

      // 补全 this.answer数据
      let newA = [...this.questionsList];
      let obj = {
        measureId: this.measureId,
        systemAccess: {
          accessToken: "md5",
        },
        isSave: true,
        answer: arr,
        inputs: {},
      };
      newA.forEach((value) => {
        if (value.title || value.text) {
          // console.log(value.title);
          obj.inputs[value.text] = value.title;
        }
      });

      if (this.measureInfo.measure_title === this.LES) {
        obj.inputs = this.inputsList;
        obj.answer.forEach((item) => {
          if (!item.answer) {
            item.answer = "";
          }
        });
      }
      obj.user_id = localStorage.getItem("user_id");
      console.log('11111111111')
      submit(obj)
        .then((res) => {
          let id = res.data.id;
          if (res.code == 400200) {
            console.log(123);
            this.$message({
              message: "恭喜你，提交成功",
              type: "success",
              showClose: true,
              duration: 1500,
            });

            // 判断是否继续填写问卷
            if (this.continueMes) {
              // 调用拉取数据列表，如果有没有做的数据，得到没有做的数据（allMesId判断）
              this.loadAnserData()

            } else {
              if (this.type === 1) {
                this.$router.push(`/answer_content/answerChart?id=${id}`);
              } else {
                this.$router.push(`/answer_content/answer_list`);
              }
            }
            this.isSubmit = false;
            localStorage.removeItem(`measureId${this.uid}-${this.measureId}`);
            localStorage.removeItem(`recordTiao${this.uid}-${this.measureId}`);
          } else {
            this.isSubmit = false;
          }
        })
        .catch((err) => {
          this.isSubmit = false;
        });
    },
    // 提交
    submit() {
      // WHO烟、酒和精神活性物质使用筛查量表(ASSIST)
      // 如果1到10题不全为A
      // if(this.flagNew==1 && this.titleGauge=="WHO烟、酒和精神活性物质使用筛查量表(ASSIST)" || this.flagNew2==true ||this.flagNew3==true){
      //   this.TobaccoAndWine()
      //   return
      // }
      if (this.flagNew == 1 && this.titleGauge == "WHO烟、酒和精神活性物质使用筛查量表(ASSIST)") {
        this.TobaccoAndWine()
        return
      }
      if (this.flagNew2 == true && this.titleGauge == "WHO烟、酒和精神活性物质使用筛查量表(ASSIST)") {
        this.TobaccoAndWine()
        return
      }
      if (this.flagNew3 == true && this.titleGauge == "WHO烟、酒和精神活性物质使用筛查量表(ASSIST)") {
        this.TobaccoAndWine()
        return
      }
      // 自杀问卷的判定
      if (this.flag == 1) {
        this.midwayJump()
        return
      }

      this.isSubmit = true;
      var arr = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      let i;
      let a = [];
      let newA = [...this.questionsList];
      let o = {};
      // 为this.answer自动补全
      this.answer.forEach((value, index) => {
        if (value.answer instanceof Array) {
          value.answer = value.answer
            .map((value) => (value = arr[value * 1]))
            .join(",");
        }
        if (value.answer) {
          i = arr.indexOf(value.answer);
          if (
            this.questionsList[index].jump_info[i] > this.questionsList.length
          ) {
            a = newA.splice(index + 1);
            a = a.map((val) => {
              o = { id: val.id, answer: "" };
              return o;
            });
            this.answer.push(...a);
          }
        }
      });
      let obj = {
        measureId: this.measureId,
        systemAccess: {
          accessToken: "md5",
        },
        isSave: true,
        answer: this.answer,
        inputs: {},
      };
      if (this.measureInfo.measure_title === this.LES) {
        obj.inputs = this.inputsList;
        obj.answer.forEach((item) => {
          if (!item.answer) {
            item.answer = "";
          }
        });
      }
      newA.forEach((value) => {
        if (value.title || value.text) {
          obj.inputs[value.text] = value.title;
        }
      });
      obj.user_id = localStorage.getItem("user_id");
      console.log('3333333333333')
      submit(obj)
        .then((res) => {
          let id = res.data.id;
          if (res.code == 400200) {
            console.log(123);
            this.$message({
              message: "恭喜你，提交成功",
              type: "success",
              showClose: true,
              duration: 1500,
            });

            // 判断是否继续填写问卷
            if (this.continueMes) {
              // 调用拉取数据列表，如果有没有做的数据，得到没有做的数据（allMesId判断）
              this.loadAnserData()

            } else {
              if (this.type === 1) {
                this.$router.push(`/answer_content/answerChart?id=${id}`);
              } else {
                this.$router.push(`/answer_content/answer_list`);
              }
            }
            this.isSubmit = false;
            localStorage.removeItem(`measureId${this.uid}-${this.measureId}`);
            localStorage.removeItem(`recordTiao${this.uid}-${this.measureId}`);
          } else {
            this.isSubmit = false;
          }
        })
        .catch((err) => {
          this.isSubmit = false;
        });
    },
    submitGame() {
      console.log(this.questionsList);
      console.log(111);
      let obj = {
        measureId: this.measureId,
        systemAccess: {
          accessToken: "md5",
        },
        isSave: true,
        answer: [],
        inputs: {},
      };
      obj.inputs = this.inputsOfGame
      obj.answer.push({ id: this.questionsList[0].id, answer: this.questionsList[0].options[0].split('、')[0] })
      obj.user_id = localStorage.getItem("user_id");
      submit(obj).then((res) => {
        let id = res.data.id;
        if (res.code == 400200) {
          this.$message({
            message: "恭喜你，提交成功",
            type: "success",
            showClose: true,
            duration: 1500,
          });

          // 判断是否继续填写问卷
          if (this.continueMes) {
            // 调用拉取数据列表，如果有没有做的数据，得到没有做的数据（allMesId判断）
            this.loadAnserData()

          } else {
            if (this.type === 1) {
              this.$router.push(`/answer_content/answerChart?id=${id}`);
            } else {
              this.$router.push(`/answer_content/answer_list`);
            }
          }
          this.isSubmit = false;
          localStorage.removeItem(`measureId${this.uid}-${this.measureId}`);
          localStorage.removeItem(`recordTiao${this.uid}-${this.measureId}`);
        } else {
          this.isSubmit = false;
        }
      })
        .catch((err) => {
          this.isSubmit = false;
        })
    },
    // 脚本:自动点击题目
    handleClick() {
      // const r = document.querySelectorAll(".centen table tr");
      // const c = r[0].querySelector("label");
      // c.click()
      let c = document.querySelectorAll(".el-checkbox");
      c[0].click();
      c[1].click();
      this.next();
    },
    // 文本框失去焦点事件
    handleBlur() {
      this.$nextTick(() => {
        const input = document.querySelector(".main-title input");
        if (input) {
          input.oninput = () => {
            this.questionsList[this.index].title = input?.value;
          };
        }
      });
    },
    handleCheckBox(val) {
      let max = this.questionsList[this.index].extra.checkbox?.max;
      let min = this.questionsList[this.index].extra.checkbox?.min;
      if (this.checkList.length > max) {
        this.checkList.pop();
        return this.$message.warning(`最多选择${max}个`);
      }
    },
    changeSubject(index, text) {
      if (index == 10) {
        // 所有题目
        // console.log("换掉题目",this.questionsList);
        let arr = text.split(",")
        // console.log("数组",arr);
        this.newAr = [];
        for (var i = 0; i < 6; i++) {
          arr.forEach((item, index) => {
            if (item == "A") {
              // console.log(index);
              // 打出来是选中的数组数据
              // console.log(this.neWquestions[(index + 1)][i].num);
              this.newAr.push(this.neWquestions[(index + 1)][i])
            }
          })
        }
        let arr2 = this.questionsList.filter(((item, index) => {
          if (index < 10) {
            return item
          }
        }))
        this.questionsList = arr2.concat(this.newAr)
        // console.log(text);
        this.questionsList.push(this.oldObj);
        this.oldquestionsList = []
        this.questionsList.forEach(item => {
          this.oldquestionsList.push(item)
        })
        // 保存30题前的最后一题num
        let arr3 = [];
        this.questionsList.filter((item, index) => {
          if (item.num > 20) {
            arr3.push(this.questionsList[index - 1].num)
            // console.log("十题时保存num",this.questionsList[index - 1].num);
          }
        })
        this.thirty = arr3[0];
        //  this.questionsList.push()
      };

      if (index == 30) {
        console.log("第20位", text);
        let str = text
        // console.log("截取",text.substring(text.length/2 + 1,text.length));
        let arr = str.split(",");
        // console.log("数组全为B",arr);
        let isHave = arr.every(function (item) {
          return item == 'A'
        })
        // console.log("全为B",isHave);
        if (isHave) {
          this.questionsList = this.questionsList.filter(item => {
            if (item.num < 21 || item.num > 50) {
              return item
            }
          });
          this.flagNew2 = true;
          // console.log("选B时刻修正的",this.questionsList);
        } else {
          // console.log("原本的",this.oldquestionsList);
          this.flagNew2 = false;
          this.questionsList = this.oldquestionsList.filter(item => {
            return item
          })
        }
      };
    }
  },
  watch: {
    changeTen: function (newVal, oldVal) {
      // 第十题如果发生了改变
      // console.log("newVal",newVal.substring(0,20));
      // console.log("oldVal",oldVal.substring(0,20));
      if (this.flagNew != 0) {
        if (newVal.substring(0, 20) != oldVal.substring(0, 20)) {
          // console.log(newVal.substring(0,20),oldVal.substring(0,20));
          // 调用处理题目方法;把后续方法改掉
          this.changeSubject(10, newVal.substring(0, 20));
        }
      } else {
        let arr3 = [];
        this.questionsList.filter((item, index) => {
          if (item.num > 20) {
            arr3.push(this.questionsList[index - 1].num)
          }
        })
        this.thirty = arr3[0];
        // 准备好30题的返回数据
        this.oldquestionsList = [];
        this.questionsList.forEach(item => {
          this.oldquestionsList.push(item)
        })
      }
    },
    // changeThirty:function(newVal,oldVal){
    //   console.log("xin",newVal);
    //   console.log("jiu",oldVal);
    //   // 第三十题如果发生了改变
    //   if(newVal != oldVal){
    //     // 调用处理题目方法;把后续方法改掉
    //     this.changeSubject(30,newVal);
    //   }
    // },
    second: function (val) {
      if (val >= 60) {
        this.second = 0;
        this.minute = this.minute + 1;
      }
    },
    minute: function (val) {
      if (val >= 60) {
        this.minute = 0;
        this.hour = this.hour + 1;
      }
    },
  }
};
</script>
<style lang="less">
.css6 {
  .tc-answer-content {
    height: calc(100vh - 100px);

    .answer-content-layout {
      max-width: 1200px;
      min-width: 320px;
      margin: 0 auto;
      padding-bottom: 10px;

      .title {
        text-align: center;
        font-weight: 700;
        font-size: 28px;
        padding: 20px 0;
      }

      .msg {
        padding: 15px;
      }

      .describe {
        padding: 15px;
      }

      .answer-main {
        height: calc(~"100% - 300px");
        min-height: 500px;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        // height: 100%;
        background: white;
        border-radius: 20px;
        padding: 20px;
        z-index: 1;

        .main-title {
          font-size: 25px;
          border-bottom: 1px #cccccc solid;
          color: rgb(9, 100, 204);
          padding-bottom: 10px;

          img {
            @media (min-width: 580px) {
              width: 60%;
              margin: 0 auto;
              display: flex;
            }

            @media (max-width: 580px) {
              width: 100%;
            }
          }
        }

        .centen {
          padding: 30px 0;
          width: 100%;

          table {
            width: 100%;

            tr {
              width: 100%;

              &:hover {
                background: paleturquoise;
              }

              td {
                padding: 10px 10px;
                width: 100%;

                input[type="radio"] {
                  display: none;
                }

                .fa-check-square,
                .fa-square-o {
                  font-size: 25px;
                  color: rgb(9, 100, 204);
                  vertical-align: middle;
                }

                i {
                  width: 50px;
                }

                span {
                  vertical-align: middle;
                  font-size: 20px;
                  color: rgb(9, 100, 204);
                }

                label {
                  width: 100%;
                  display: inline-block;
                }

                img {
                  width: 100px;
                  vertical-align: middle;
                }
              }
            }
          }
        }

        .active {
          background: paleturquoise;
        }
      }

      .footer {
        position: relative;
        z-index: 999;
        box-sizing: border-box;
        padding: 0 10%;
        width: 100%;
        height: 80px;
        border-radius: 0 0 20px 20px;
        box-shadow: -1px -12px 16px #9c9c9c;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgb(255, 255, 255);
        transform: translateY(-10px);

        .btn {
          .el-button {
            font-size: 28px;
          }

        }

        .sum {
          flex: 3;
          text-align: center;
          font-size: 20px;
          color: rgb(96, 98, 102);

          .sum_info {
            color: orange;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .tc-answer-content {

    // 加载等待框
    .el-loading-mask {
      .el-loading-spinner {
        .el-icon-loading {
          color: rgb(34, 77, 85);
        }

        .el-loading-text {
          color: rgb(34, 77, 85);
          font-weight: 700;
        }
      }
    }

    .answer-content-layout {
      max-width: 812px;
      min-width: 320px;
      margin: 0 auto;

      .title {
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        padding: 10px 0;

        .el-progress.el-progress--line {
          padding: 0 20px;

          .el-progress-bar__outer {
            height: 3px !important;
          }
        }
      }

      .answer-main {
        height: calc(~"100% - 150px");
        min-height: calc(100vh - 215px);
        // overflow: auto;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        background: white;
        border-radius: 20px;
        padding: 15px 20px;
        z-index: 1;

        .main-title {
          font-weight: 700;
          font-size: 18px;
          border-bottom: 1px #cccccc solid;
          color: rgb(9, 100, 204);
          padding-bottom: 10px;
        }

        .centen {
          padding: 30px 0;
          width: 100%;

          table {
            width: 100%;

            tr {
              width: 100%;

              &:hover {
                background: transparent;
              }

              &:active {
                background: paleturquoise;
              }

              td {
                padding: 10px 10px;
                width: 100%;

                input[type="radio"] {
                  display: none;
                }

                .fa-check-square,
                .fa-square-o {
                  font-size: 20px;
                  color: rgb(9, 100, 204);
                  vertical-align: middle;
                }

                i {
                  width: 30px;
                }

                span {
                  font-size: 16px;
                  color: rgb(9, 100, 204);
                  vertical-align: middle;
                }

                label {
                  width: 100%;
                  display: inline-block;
                }
              }
            }
          }
        }

        .active {
          // background: paleturquoise;
        }
      }

      .footer {
        position: relative;
        z-index: 999;
        box-sizing: border-box;
        padding: 0 3%;
        width: 100%;
        height: 80px;
        border-radius: 0 0 20px 20px;
        box-shadow: -1px -12px 16px #9c9c9c;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgb(255, 255, 255);
        transform: translateY(-10px);

        .btn {
          .el-button {
            font-size: 14px !important;
            width: 80px;
          }
        }

        .sum {
          flex: 3;
          text-align: center;
          font-size: 8px !important;
          color: rgb(96, 98, 102);

          .sum_info {
            color: orange;
          }
        }
      }
    }
  }
}
</style>
<style lang="less" scoped>
@media (min-width: 750px) and (max-width: 2866px) {
  .scrollss {
    max-height: 220px;
    overflow-y: scroll;
  }

  .css4 {
    height: calc(100vh - 100px);
    // background: #1b484b;
    background: url("../../../assets/images/children_four.png") no-repeat center center;
    background-size: cover;

    .tc-answer-content {
      height: 100%;

      .answer-content-layout {
        max-width: 800px;
        min-width: 320px;
        margin: 0 auto;
        padding-bottom: 10px;

        .css44 {
          width: 400px;
          margin-left: 50%;
          // padding-top: 60px;
          padding-top: 20%
        }

        .title {
          text-align: center;
          font-weight: 700;
          font-size: 28px;
          padding: 20px 0;
        }

        .msg {
          padding: 15px;
        }

        .describe {
          padding: 15px;
        }

        .answer-main {
          height: calc(~"100% - 300px");
          min-height: 500px;
          position: relative;
          box-sizing: border-box;
          width: 100%;
          // height: 100%;
          // background: white;
          border-radius: 20px;
          padding: 20px;
          z-index: 1;

          .main-title {
            font-size: 25px;
            border-bottom: 1px #cccccc solid;
            color: rgb(9, 100, 204);
            padding-bottom: 10px;

            img {
              @media (min-width: 580px) {
                width: 60%;
                margin: 0 auto;
                display: flex;
              }

              @media (max-width: 580px) {
                width: 100%;
              }
            }
          }

          .centen {
            padding: 30px 0;
            width: 100%;

            table {
              width: 100%;

              tr {
                width: 100%;

                &:hover {
                  background: paleturquoise;
                }

                td {
                  padding: 10px 10px;
                  width: 100%;

                  input[type="radio"] {
                    display: none;
                  }

                  .fa-check-square,
                  .fa-square-o {
                    font-size: 25px;
                    color: rgb(9, 100, 204);
                    vertical-align: middle;
                  }

                  i {
                    width: 50px;
                  }

                  span {
                    vertical-align: middle;
                    font-size: 20px;
                    color: rgb(9, 100, 204);
                  }

                  label {
                    width: 100%;
                    display: inline-block;
                  }

                  img {
                    width: 100px;
                    vertical-align: middle;
                  }
                }
              }
            }
          }

          .active {
            background: paleturquoise;
          }
        }

        .footer {
          display: flex;
          position: relative;
          z-index: 999;
          align-items: center; //定义项目在交叉轴上如何对齐
          justify-content: center; //定义项目在主轴上的如何对齐
          top: -40px;

          .btn {
            margin-right: 4px;

            .el-button {
              font-size: 28px;
              width: 160px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 18px 94px;
              border-radius: 7px;
              font-weight: bold;
              background: #FFFFFF;
              font-family: SourceHanSansCN-Medium;
              font-size: 16px;
              // font-weight: 500;
              line-height: 16px;
              letter-spacing: 0px;
              border: 0px;

              &:hover {
                box-sizing: border-box;
                color: #15C5CE !important;
                /* 综合医院-辅助色 */
                border: 1px solid #15C5CE;
              }
            }

          }

          .sum {
            flex: 3;
            text-align: center;
            font-size: 20px;
            color: rgb(96, 98, 102);

            .sum_info {
              color: orange;
            }
          }
        }
      }

      .go-btn {
        border-radius: 20px;
        background: #FF8A1D;
        box-sizing: border-box;
        border: 2px solid rgba(255, 255, 255, 0.2);
        font-size: 20px;
        font-weight: bold;
        // line-height: 20px;
        letter-spacing: 0px;
        color: #FFFFFF;
      }
    }

    .student-left-box {
      // position: absolute;
      // left: 10px;
      // top: 170px;
      width: 320px;
      height: 529px;
      border-radius: 40px;
      background: #FFFFFF;
      box-sizing: border-box;
      padding: 24px;
      /* 外部/文字/辅助色 淡 */
      border: 1px solid #C3CBD6;

      .title {
        font-family: SourceHanSansCN-Medium;
        font-size: 20px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: center;
      }

      .text-box {
        font-family: SourceHanSansCN-Bold;
        font-size: 14px;
        font-weight: bold;
        line-height: 14px;
        letter-spacing: 0px;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .div-box {
        display: flex;
        flex-flow: wrap;
        height: 360px;
        overflow-y: scroll;

        .div-box-one {
          div {
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 7px;
            background: #DEE0E2;
            margin-left: 20px;
            margin-top: 10px;
          }

          .one {
            background: rgba(26, 211, 173, 0.14);
            box-sizing: border-box;
            /* 综合医院-辅助色 */
            border: 1px solid #15C5CE;
            color: #15C5CE;
          }

          .two {
            background: #F7F8FB;
          }
        }
      }

      .bottom-box {
        display: flex;
        margin-top: 25px;

        >div {
          // flex: 1;
          display: flex;
          align-items: center; //定义项目在交叉轴上如何对齐
          justify-content: center; //定义项目在主轴上的如何对齐
        }
      }
    }

    .student-left-top {
      // position:absolute;
      // top: 110px;
      // left: 10px;
      width: 320px;
      height: 50px;
      // background-color: #15C5CE;
      display: flex;
      align-items: center; //定义项目在交叉轴上如何对齐
      justify-content: center; //定义项目在主轴上的如何对齐

      .back-btn {
        width: 138px;
        height: 32px;
        border-radius: 7px;

        // background: #F4F6F8;
        .btn {
          width: 138px;
          height: 32px;
          border-radius: 7px;
          background: #F4F6F8;
          border: none;
          font-family: SourceHanSansCN-Medium;
          font-size: 14px;
          font-weight: 500;
          line-height: 32px;
          letter-spacing: 0px;

          /* 外部/文字/辅助 */
          color: #717A8B;
        }
      }
    }
  }

  .css5 {
    // height: calc(100vh);
    height: calc(100vh - 100px);
    background: url("../../../assets/images/children_fore.png") no-repeat center center;
    background-size: cover;
    display: flex;
  }

  .progress-line-box {
    -ms-transform: rotate(90deg);
    /* IE 9 */
    -webkit-transform: rotate(90deg);
    /* Safari and Chrome */
    transform: rotate(90deg);
    position: relative;
    left: 800px;
    // overflow: hidden;
    top: 240px;
    width: 500px;

    // right:  100px;
    /deep/.el-progress__text {
      // -ms-transform:rotate(90deg); /* IE 9 */
      // -webkit-transform:rotate(180deg); /* Safari and Chrome */
      transform: rotate(270deg);
    }

    /deep/.el-progress-bar__outer {
      transform: rotate(180deg);
    }
  }

}

@media screen and (max-width: 750px) {
  .my_left_box {
    display: none !important;
  }
}

.container {
  width: 100%;
  height: 100vh;
}

.heads {
  width: 600px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.main {
  width: 100%;
  height: 80vh;
}

.text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text2 span {
  color: orange;
}

.alls {
  width: 80%;
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

.in_red {
  width: 90px;
  height: 90px;
  background-color: green;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.btnstart {
  width: 100px;
}</style>